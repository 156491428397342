import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { quoteLinesFn } from './linesHandlers'
import { quoteAdditionalLinesFn } from './addonsHandlers'
import { ratesFn } from './ratesHandlers'
import { zonesFn } from './zonesHandlers'
import { useUpdateQuote } from './updateQuote'
import {
	IQuoteForm,
	IQuoteAdditionalLinesHandlers,
	IQuoteLinesHandlers,
	IRatesHandlers,
	IZonesHandlers,
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
	columnsQuoteLines,
	IQuoteFiles,
} from 'models/simple_quotes.model'
import { useServiceRates } from './useServiceRates'
import { totalsFn } from './totalHandlers'
import { useCreateQuote } from './createQuote'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'config'
import { assignDataToEdit } from './assignDataToEdit'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { Roles } from 'models'

interface IUseFormHandler {
	quote_id?: number
	quote_data?: QuoteData
	quote_lines?: IQuoteLine[]
	quote_addons?: IQuoteAdditionalLines[]
	quote_rates?: IRates[]
	quote_zones?: IQuoteZones[]
	quote_files?: IQuoteFiles[]
}

const itemsTabsAdmin = [
	{ label: 'Sections', id: 0 },
	{ label: 'Quote Rates', id: 1 },
	{ label: 'Default Rates Edit', id: 2 },
]

const itemsTabsStandard = [
	{ label: 'Sections', id: 0 },
	{ label: 'Rates', id: 1 },
]

export const useSimpleFormHandler = ({
	quote_id,
	quote_data,
	quote_lines,
	quote_addons,
	quote_rates,
	quote_zones,
	quote_files,
}: IUseFormHandler) => {
	const loggedInUserData = useSelector((store: AppStore) => store.user)

	let itemsTabs = []

	if (loggedInUserData?.userType === Roles.admin && !quote_id) {
		itemsTabs = itemsTabsAdmin
	} else {
		itemsTabs = itemsTabsStandard
	}

	const navigate = useNavigate()
	const { createQuote } = useCreateQuote()
	const {
		updateQuote,
		updateQuoteAddOns,
		updateQuoteLines,
		updateQuoteRates,
		updateQuoteZones,
		updateQuoteFiles,
	} = useUpdateQuote()

	const [editInfoLoaded, setEditInfoLoaded] = useState(false)
	const [backToMainTable, setBackToMainTable] = useState(false)

	const ratesInfo = useServiceRates()

	const validationSchema = Yup.object().shape({
		job_type: Yup.string().required('Job Type Is Required'),
		estimator: Yup.number().min(1, 'Estimator is Required'),
		client: Yup.string().required('Client is Required'),
		client_contact: Yup.string().required('Contact is Required'),
		variation_job_id: Yup.string().when('quote_type', (quote_type) => {
			if (quote_type[0] === 'Variation') {
				return Yup.string().required('Variation Job ID is required')
			}
			return Yup.string().nullable()
		}),
		zones: Yup.array().of(
			Yup.object().shape({
				zone_label: Yup.string().required('Section is required'),
				description: Yup.string().required('Description is required'),
			})
		),
		max_zones: Yup.number()
			.required('Max Sections is Required')
			.min(1, 'Max Sections is Required'),
	})

	const initialValues: IQuoteForm = {
		quote_type: 'New',
		job_type: '',
		variation_job_id: '',
		PO_Number: '',
		max_zones: 1,
		client: null,
		client_contact: null,
		quote_num: '',
		scope_of_work: `Thank you for the opportunity to quote for your project, please see below quote details together with attached scaffold building plans.
The quoting scaffold work is with/ without roof protection for roofers, top deck around 1 meter/2 meter below roofline. (One alteration is included to drop the top platform down to 2 meter below once roof work done.)`,
		estimator: null,

		fullAddress: '',
		street: '',
		country: '',
		street2: '',
		city: '',
		postal: '',

		quote_lines: [],

		quote_additional_lines: [],

		// TODO: Do this some other way that isn't UGLY
		terms: `General Notes:
• This quote is valid for 3 months from issue date, a site meeting will be needed to fully discuss and finalise the price.  Any scop of work outside of this quoting or not indicating from attached scaffold drawing plan would be a variation. 
• Price is for install and dismantle all scaffold at one time, extra travelling and transport charges may apply if scaffold is built or dismantled in different stages.
• At least two-week pre-notice is required for any erect, dismantle and variation work.
• The client is responsible to offer a safe and accessible environment for the loading & unloading work, including supplying necessary safety signs, spotters, crane and traffic controlling measures. The trucks need to be able to park within 10 meters of the scaffold location, and there is clear pathway for truck Moffett to move scaffold gears Insufficient safety measures or site obstacles may cause the failure of scaffold delivery, extra charge may apply for the rescheduling of transportation.
• Power Line Hazards within 4 meters to the building, safety advice report may be required from Energex by the client. Extra charge may apply for the plywood as the safety barrier.
• Scaffold ties have been allowed to be fixed and removed once only. Any adjustment will be charged as a variation.
• No retention of money will apply to scaffold contracts. Any alterations after initial set up will be charged as per contract labour rate.

Mandatory Inspection:
Please note it is a legislative requirement that any scaffold at a workplace must be inspected by a competent person at least every 30 days at hourly charge. Should the inspection reveal that the scaffold is unsafe due to tampering, weather events, a "Scaffold Incomplete" sign will be placed on the scaffold and a quotation will be provided to rectify the scaffold.

Payment Terms:
Invoice will be issued based on each building or stage.
A security deposit of 30% of the Price within 5 business days of requested installation date.
A progress payment of 60% at completion of the installation.
A final payment of the balance 10% at contract expired hiring week.
Extra days will be charged as ongoing hire fee after contract term expires, at the end of each month or until the date of off hire.  If scaffold is requested to be dismantled earlier than the contract term, full contract price is still payable.
NOTE: Job value Less than AU $3000 will be charged 100% of the total price.
 
Account Information:
Bank: Commonwealth Bank    Account Name: N&B SCAFFOLD     BSB: 064162     AC#: 11674020     Ref#: Quote Number
Labour Cost:
$80.00 + GST per person per hour. Minimum call out fee $160 + GST will apply. Extra transport fee will be $400.00 + GST per load. Monthly scaffold safety inspections will be charged at a minimum 2 hours for 1 man at $80.00 per hour.`,

		erectDismantleTotal: 0,
		additionalTotal: 0,
		weekTotal: 0,
		total: 0,

		rates: ratesInfo.data,
		zones: [],
		file_1: '',
		file_2: '',
		file_3: '',
		file_4: '',
		files: [],
		max_files: 4,
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			try {
				if (!editInfoLoaded) {
					// when the quote is new execute create a quote
					const resultQuote = await createQuote({
						...values,
						estimator: Number(values.estimator),
						client: Number(values.client),
					})
					if (resultQuote?.quote_id) {
						navigate(AppRoutes.privateRoutes.Quotes)
					}
				} else {
					// when the quote is edited execute update a quote
					const quoteData: QuoteDataToUpdate = {
						job_type: values.job_type,
						quote_type: values.quote_type,
						max_zones: values.max_zones,
						max_files: values.max_files,
						client: Number(values.client),
						client_contact: values.client_contact,
						scope_of_work: values.scope_of_work,
						estimator: Number(values.estimator),

						fullAddress: values.fullAddress,
						street: values.street,
						country: values.country,
						street2: values.street2,
						city: values.city,
						postal: values.postal,

						terms: values.terms,

						erectDismantleTotal: Number(values.erectDismantleTotal),
						additionalTotal: Number(values.additionalTotal),
						weekTotal: Number(values.weekTotal),
						total: Number(values.total),
						variation_job_id: null,
						PO_Number: null,
						file_1: values.file_1,
						file_2: values.file_2,
						file_3: values.file_3,
						file_4: values.file_4,
					}
					updateQuote(quoteData, quote_id as number)
					updateQuoteAddOns(values.quote_additional_lines, quote_id as number)
					updateQuoteLines(values.quote_lines, quote_id as number)
					updateQuoteRates(values.rates, quote_id as number)
					updateQuoteZones(values.zones, quote_id as number)
					updateQuoteFiles(values.files, quote_id as number)

					if (backToMainTable) navigate(AppRoutes.privateRoutes.Quotes)
					else
						navigate(
							AppRoutes.privateRoutes.QuotesDetail.replace(
								':id',
								String(quote_id)
							)
						)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	const quoteLinesHandlers: IQuoteLinesHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteLinesFn.addNewQuoteLine(formik.values.quote_lines)
			formik.setFieldValue('quote_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteLinesFn.removeQuoteLine(
				index,
				formik.values.quote_lines
			)
			formik.setFieldValue('quote_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteLinesFn.updateQuoteLine(
				index,
				formik.values.quote_lines,
				field,
				value
			)
			newQuoteLines = quoteLinesFn.checkZones(
				formik.values.zones,
				newQuoteLines
			)
			newQuoteLines = quoteLinesFn.calculateQuoteLines(
				formik.values.rates,
				newQuoteLines,
				field
			)
			formik.setFieldValue('quote_lines', newQuoteLines)
		},
		validateQuoteLinesColumns: () => {
			return columnsQuoteLines.map((column) => column)
		},
	}

	const quoteAdditionalLinesHandlers: IQuoteAdditionalLinesHandlers = {
		addNewQuoteAdditionalLine: () => {
			const newline = quoteAdditionalLinesFn.addNewQuoteLine(
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', newline)
		},
		removeQuoteAdditionalLine: (index: number) => {
			const lines = quoteAdditionalLinesFn.removeQuoteLine(
				index,
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', lines)
		},
		updateQuoteAdditionalLine: async (
			index: number,
			field: string,
			value: string | number
		) => {
			let lines = quoteAdditionalLinesFn.updateQuoteLine(
				index,
				formik.values.quote_additional_lines,
				field,
				value
			)
			lines = quoteAdditionalLinesFn.calculateLines(
				index,
				lines,
				formik.values.rates,
				field
			)
			if (field === 'zone_id') {
				lines = quoteAdditionalLinesFn.checkZones(
					formik.values.zones,
					lines,
					index,
					value
				)
			}

			formik.setFieldValue('quote_additional_lines', lines)
		},
	}

	const ratesHandlers: IRatesHandlers = {
		addNewRate: () =>
			ratesFn.addNewRate(formik.values.rates, formik.setFieldValue),
		removeRate: (index: number) =>
			ratesFn.removeRate(index, formik.values.rates, formik.setFieldValue),
		updateRate: (index: number, field: string, value: string | number) =>
			ratesFn.updateRate(
				index,
				formik.values.rates,
				formik.setFieldValue,
				field,
				value
			),
	}

	const zonesHandlers: IZonesHandlers = {
		addNewZone: (zone_id: number) =>
			zonesFn.addNewZone(zone_id, formik.values.zones, formik.setFieldValue),
		removeZone: (index: number) =>
			zonesFn.removeZone(index, formik.values.zones, formik.setFieldValue),
		updateZone: (index: number, field: string, value: string | number) =>
			zonesFn.updateZone(
				index,
				formik.values.zones,
				formik.setFieldValue,
				field,
				value
			),
	}

	useEffect(() => {
		if (
			quote_data &&
			quote_lines &&
			quote_addons &&
			quote_rates &&
			quote_zones &&
			quote_files &&
			!editInfoLoaded
		) {
			const newData = assignDataToEdit({
				quote_data,
				quote_lines,
				quote_addons,
				quote_rates,
				quote_zones,
				quote_files,
			})
			setTimeout(() => {
				formik.setValues(newData)
				setEditInfoLoaded(true)
			}, 200)
		}
	}, [
		quote_data,
		quote_lines,
		quote_addons,
		quote_rates,
		quote_zones,
		quote_files,
	])

	useEffect(() => {
		if (ratesInfo.data && !editInfoLoaded && !quote_id) {
			formik.setFieldValue('rates', ratesInfo.data)
		}
	}, [ratesInfo.data])

	// Check the zones when the max_zones changes
	useEffect(() => {
		zonesFn.checkZones(
			formik.values.max_zones,
			formik.values.zones,
			formik.setFieldValue
		)
	}, [formik.values.max_zones])

	// Check the quote lines when the zones, rates or estimatedWay changes
	useEffect(() => {
		let newQuoteLines = quoteLinesFn.checkZones(
			formik.values.zones,
			formik.values.quote_lines
		)
		newQuoteLines = quoteLinesFn.calculateQuoteLines(
			formik.values.rates,
			newQuoteLines
		)
		// console.log('wwww')
		formik.setFieldValue('quote_lines', newQuoteLines)
	}, [formik.values.zones, formik.values.rates])

	//Functions to calculate the totals
	useEffect(() => {
		const erectDismantleTotal = totalsFn.calculateEDtotal(
			formik.values.quote_lines
		)

		const additionalTotal = totalsFn.calculateAdditionalTotal(
			formik.values.quote_additional_lines
		)

		const weekTotal = totalsFn.calculateWeekTotal(formik.values.quote_lines)

		const total = erectDismantleTotal + additionalTotal

		formik.setFieldValue('erectDismantleTotal', erectDismantleTotal)
		formik.setFieldValue('additionalTotal', additionalTotal)
		formik.setFieldValue('weekTotal', weekTotal)
		formik.setFieldValue('total', total)
	}, [formik.values.quote_lines, formik.values.quote_additional_lines])

	return {
		formik,
		quoteLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	}
}
