import { JobsServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { EventDropArg } from '@fullcalendar/core'
import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'

export function JobVisitsSchedulerUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const filteredVisitData = visitsData?.filter(
		(visit: { status: string }) => visit.status === 'Active'
	)
	// const {data: taskData, isLoading: taskLoading} = JobsServices.useTask()
	const { updateVisit } = VisitServices.useUpdateVisit()
	const { showInfo } = useContext(NotificationsContext)
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async (eventArgs: EventDropArg) => {
		const event = eventArgs.event;
		const visitId = Number(event.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload: 
			{
				date: string
				team_leader_id?: number
			 } = {
				date: newDate,
			}
			if(eventArgs?.newResource?.id && eventArgs.newResource.id !== null && eventArgs.newResource.id !== undefined){
				// console.log('changing resource')
				payload.team_leader_id = Number(eventArgs?.newResource?.id)
			}
			// console.log(event, 'dropped event')
			// console.log(event?.groupId, 'groupid')
			// console.log(payload)
			await updateVisit(visitId, payload)
			showInfo('Team leader updated')
		}
	}

	const calculateColors = (teamLeaderId: number) => {
		/*Chhris M > 7 #44A4F0
Chris/ is 6 #ED8859
Daniel is 9 #58C487
Luke is 20 #A17CCB
Mikey is 23 #3FADB2
Phillip is 26 #EF61BE
Rua is 27 #868686
Sam is 28 #BD835E*/
		let textColor = ''
		let backgroundColor = ''
		switch (teamLeaderId) {
			case 7:
				backgroundColor = '#44A4F0'
				textColor = 'white'
				break
			case 6:
				backgroundColor = '#ED8859'
				textColor = 'white'
				break
			case 9:
				backgroundColor = '#58C487'
				textColor = 'white'
				break
			case 20:
				backgroundColor = '#A17CCB'
				textColor = 'white'
				break
			case 23:
				backgroundColor = '#3FADB2'
				textColor = 'white'
				break
			case 26:
				backgroundColor = '#EF61BE'
				textColor = 'white'
				break
			case 27:
				backgroundColor = '#868686'
				textColor = 'white'
				break
			case 28:
				backgroundColor = '#BD835E'
				textColor = 'white'
				break
			case 47:
				backgroundColor = 'gray'
				textColor = 'white'
				break
			default:
				backgroundColor = '#D15C62'
				textColor = 'white'
				break
		}
		return {
			textColor: textColor,
			backgroundColor: backgroundColor,
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && filteredVisitData) {
			return filteredVisitData.map((visit: any) => {
				const { textColor, backgroundColor } = calculateColors(
					visit.teamLeaderData?.id
				)

				// Filter taskData down based on whether it's id is in list visit.task_ids
				// const tasks = taskData?.filter((task: { id: number }) =>
				// 	visit.task_ids.includes(String(task.id))
				// )

				return {
					id: visit.id,
					resourceId: visit.teamLeaderData?.id,
					job_id: visit?.jobData?.job_num,
					title: visit.visit_status,
					teamLeader: visit.teamLeaderData?.staff_name || '',
					description: visit?.jobData?.descriptionOfQuote,
					client_name: visit?.jobData?.clientData?.client_name,
					// tasks: tasks,
					textColor: textColor,
					backgroundColor: backgroundColor,
					type: visit?.type,
					site: visit.jobData?.site,
					start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				}
			})
		}
		return []
	}

	return {
		formatEvents,
		visitsData: filteredVisitData,
		visitsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
	}
}
