import { pdf } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
} from 'models'
import { QuotePDFDocument } from './Output'

interface QuotePDFBlodProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_zones: IQuoteZones[]
}

export const MakeBlodQuote = async ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_zones,
}: QuotePDFBlodProps) => {
	const newBlod = await pdf(
		QuotePDFDocument({ quote_data, quote_lines, quote_addons, quote_zones })
	).toBlob()

	return newBlod
}
