import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { StyledWrapper } from './styledComponents'
import { EventSchedularUtils } from './EventSchedularUtils'
import { PageHeading, Spinner } from 'common'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'
import { PlusIcon } from '@heroicons/react/24/solid'
import { DateSelectArg } from '@fullcalendar/core'
import moment from 'moment'

export function EventScheduler() {
	const [openEventForm, setOpenEventForm] = useState(false)
	const [selectedEvent, setSelectedEvent] = useState({
		start: '',
		team_leader: '',
	})
	const {
		eventsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = EventSchedularUtils()

	useEffect(() => {
		if (!openEventForm) {
			setSelectedEvent({
				start: '',
				team_leader: '',
			})
		}
	}, [openEventForm])

	const handleDateSelect = (selectInfo: DateSelectArg) => {
		setSelectedEvent({
			start: selectInfo.startStr,
			team_leader: selectInfo.resource ? selectInfo.resource.id : '',
		})
		setOpenEventForm(true)
	}

	if (eventsLoading) {
		return <Spinner />
	}

	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenEventForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Create Event
				</button>
			</div>
			<FullCalendar
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				slotMinWidth={100}
				views={{
					Day: {
						type: 'dayGridDay',
						allDaySlot: false,
						slotDuration: { hours: 1 },
						slotEventOverlap: false,
						expandRows: true,
						nowIndicator: true,
						slotMinTime: '06:00:00',
						slotMaxTime: '18:00:00',
						now: moment(Date.now()).format(),
						eventMinHeight: 60,
						eventContent,
					},
					Week: {
						type: 'dayGridWeek',
						weekends: true,
						allDaySlot: false,
						slotEventOverlap: false,
						slotDuration: { hours: 1 },
						nowIndicator: true,
						slotMinTime: '06:00:00',
						slotMaxTime: '18:00:00',
						now: moment(Date.now()).format(),
						eventMinHeight: 60,
						eventContent,
					},
					Month: {
						type: 'dayGridMonth',
						weekends: true,
						allDaySlot: false,
						slotEventOverlap: false,
						slotDuration: { hours: 1 },
						nowIndicator: true,
						slotMinTime: '06:00:00',
						slotMaxTime: '18:00:00',
						now: moment(Date.now()).format(),
						eventMinHeight: 60,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
				]}
				initialView="Week"
				height="auto"
				timeZone='Pacific/Auckland'
				eventClick={(clickInfo) => {alert('test')}}
				selectable
				select={handleDateSelect}
				firstDay={1}
				events={formatEvents()}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
			{/* <VisitForm
				heading="Schedule Visit"
				setOpen={setOpenEventForm}
				formType="create"
				open={openEventForm}
				startDate={selectedEvent.start}
				teamLeader={selectedEvent.team_leader}
			/> */}
		</StyledWrapper>
	)
}
