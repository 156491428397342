import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteLine,
	IQuoteZones,
	IRates,
	IQuoteLinesHandlers,
	estimatedWay,
} from 'models'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import { useState } from 'react'

interface QuoteLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteLine
	quoteLinesHandlers: IQuoteLinesHandlers
	index: number
	estimatedWayOption: estimatedWay
}

export const QuoteLine = ({
	zones,
	rates,
	data,
	index,
	quoteLinesHandlers,
	estimatedWayOption,
}: QuoteLineProps) => {
	const [dirtyFields, setDirtyFields] = useState({
		erect_and_dismantle: false,
		weekly_hire_fee: false,
	})

	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteLine,
		value: string | number
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		// <tr>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
		// 		<Dropdown
		// 			title="zone_id"
		// 			options={OptionsForDropdown(zones, 'zone_id', 'zone_id')}
		// 			id={`zone_id${data.id}`}
		// 			value={data.zone_id}
		// 			onChange={(e) =>
		// 				handleProductLineChange(index, 'zone_id', e.target.value)
		// 			}
		// 			className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
		// 		<input
		// 			id={`zonelabel${data.id}`}
		// 			type="text"
		// 			className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 			value={data.zone_label}
		// 			disabled
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
		// 		<Dropdown
		// 			title="Type"
		// 			options={OptionsForDropdown(rates, 'service', 'service')}
		// 			id={`type${data.id}`}
		// 			value={data.type}
		// 			onChange={(e) =>
		// 				handleProductLineChange(index, 'type', e.target.value)
		// 			}
		// 			className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
		// 		<input
		// 			id={`description${data.id}`}
		// 			type="text"
		// 			className="h-8 px-1 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
		// 			value={data.description}
		// 			onChange={(e) =>
		// 				handleProductLineChange(index, 'description', e.target.value)
		// 			}
		// 		/>
		// 	</td>
		// 	{estimatedWayOption === estimatedWay.SQM ? (
		// 		<>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 				<input
		// 					id={`length${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 					value={data.length}
		// 					onChange={(e) =>
		// 						handleProductLineChange(index, 'length', Number(e.target.value))
		// 					}
		// 				/>
		// 			</td>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 				<input
		// 					id={`height${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 					value={data.height}
		// 					onChange={(e) =>
		// 						handleProductLineChange(index, 'height', Number(e.target.value))
		// 					}
		// 				/>
		// 			</td>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 				<input
		// 					id={`width${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 					value={data.width}
		// 					onChange={(e) =>
		// 						handleProductLineChange(index, 'width', Number(e.target.value))
		// 					}
		// 				/>
		// 			</td>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 				<input
		// 					id={`total_dimensions${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 					value={data.total_dimensions}
		// 					onChange={(e) =>
		// 						handleProductLineChange(
		// 							index,
		// 							'total_dimensions',
		// 							Number(e.target.value)
		// 						)
		// 					}
		// 					disabled
		// 				/>
		// 			</td>
		// 		</>
		// 	) : null}
		// 	{estimatedWayOption !== estimatedWay.SQM ? (
		// 		<>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
		// 				<input
		// 					id={`quantity${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
		// 					value={data.quantity}
		// 					disabled={
		// 						!data.type || estimatedWayOption === estimatedWay.TotalED
		// 					}
		// 					onChange={(e) =>
		// 						handleProductLineChange(
		// 							index,
		// 							'quantity',
		// 							Number(e.target.value)
		// 						)
		// 					}
		// 				/>
		// 			</td>
		// 			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
		// 				<input
		// 					id={`total_days${data.id}`}
		// 					type="number"
		// 					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
		// 					value={data.total_days}
		// 					disabled
		// 					onChange={(e) =>
		// 						handleProductLineChange(index, 'total_days', e.target.value)
		// 					}
		// 				/>
		// 			</td>
		// 		</>
		// 	) : null}
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 		<input
		// 			id={`erect_and_dismantle${data.id}`}
		// 			type="text"
		// 			className="h-8 px-1 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 			value={Number(data.erect_and_dismantle)}
		// 			onChange={(e) =>
		// 				handleProductLineChange(
		// 					index,
		// 					'erect_and_dismantle',
		// 					parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
		// 				)
		// 			}
		// 			disabled={estimatedWayOption === estimatedWay.Hours}
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 		<input
		// 			id={`percentageWeeklyHireFee${data.id}`}
		// 			type="number"
		// 			className="h-8 px-1 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
		// 			value={data.percentage_weekly_hire_fee}
		// 			onChange={(e) =>
		// 				handleProductLineChange(
		// 					index,
		// 					'percentage_weekly_hire_fee',
		// 					Number(e.target.value)
		// 				)
		// 			}
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 		<input
		// 			id={`weekly_hire_fee${data.id}`}
		// 			type="text"
		// 			className="h-8 px-1 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
		// 			value={numberFormat.format(Number(data.weekly_hire_fee))}
		// 			onChange={(e) =>
		// 				handleProductLineChange(index, 'weekly_hire_fee', e.target.value)
		// 			}
		// 			disabled
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
		// 		<input
		// 			id={`total${data.id}`}
		// 			type="text"
		// 			className="h-8 px-1 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
		// 			value={numberFormat.format(Number(data.total))}
		// 			disabled
		// 		/>
		// 	</td>
		// 	<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
		// 		<ConfirmationDialog
		// 			icon="danger"
		// 			title="Delete Line Item"
		// 			body="Are you sure you want to delete this item? This action is unrecoverable!"
		// 			triggerButton={
		// 				<button type="button">
		// 					<XCircleIcon
		// 						className="flex-shrink-0 h-4 w-4 text-red-500"
		// 						aria-hidden="true"
		// 					/>
		// 				</button>
		// 			}
		// 			confirmButton={
		// 				<Button
		// 					className="bg-red-600 text-white"
		// 					onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
		// 					Delete Line
		// 				</Button>
		// 			}
		// 		/>
		// 	</td>
		// </tr>
		<tr>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id-${index}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Type"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type-${index}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<input
					id={`description-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`bays-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.bays}
					onChange={(e) =>
						handleProductLineChange(index, 'bays', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`perimeter-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.perimeter}
					onChange={(e) =>
						handleProductLineChange(index, 'perimeter', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`height-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.height}
					onChange={(e) =>
						handleProductLineChange(index, 'height', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`square_meters-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.square_meters}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'square_meters',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`labor_cost-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.labor_cost}
					onChange={(e) =>
						handleProductLineChange(index, 'labor_cost', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`weight-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.weight}
					onChange={(e) =>
						handleProductLineChange(index, 'weight', Number(e.target.value))
					}
				/>
			</td>
			{/* <td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`erect_and_dismantle-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.erect_and_dismantle_is_dirty ||
							dirtyFields.erect_and_dismantle
								? '#fff2e2'
								: '',
					}}
					value={Number(data.erect_and_dismantle)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'erect_and_dismantle',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, erect_and_dismantle: true })
					}}
				/>
			</td> */}
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`weekly_hire_fee-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					style={{
						background:
							data.weekly_hire_fee_is_dirty || dirtyFields.weekly_hire_fee
								? '#fff2e2'
								: '',
					}}
					value={data.weekly_hire_fee}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'weekly_hire_fee',
							Number(e.target.value)
						)
						setDirtyFields({ ...dirtyFields, weekly_hire_fee: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`weeks-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.weeks}
					onChange={(e) =>
						handleProductLineChange(index, 'weeks', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`material_hire-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.material_hire}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'material_hire',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`total-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
					value={numberFormat.format(Number(data.total))}
					defaultValue={numberFormat.format(Number(data.total))}
					disabled
				/>
			</td>
			<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<ConfirmationDialog
					icon="danger"
					title="Delete Line Item"
					body="Are you sure you want to delete this item? This action is unrecoverable!"
					triggerButton={
						<button type="button">
							<XCircleIcon
								className="flex-shrink-0 h-4 w-4 text-red-500"
								aria-hidden="true"
							/>
						</button>
					}
					confirmButton={
						<Button
							className="bg-red-600 text-white"
							onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
							Delete Line
						</Button>
					}
				/>
			</td>
		</tr>
	)
}
