import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
} from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_zones: IQuoteZones[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_zones,
}: QuotePDFDocumentProps) => {
	const linesTotal = quote_lines
		.map((line) => Number(line.total))
		.reduce((acc, curr) => acc + curr, 0)
	// const linesHireTotal = quote_lines
	// 	.map((line) => Number(line.material_hire))
	// 	.reduce((acc, curr) => acc + curr, 0)
	const additionalTotal = quote_addons
		.map((addon) => Number(addon.total_cost))
		.reduce((acc, curr) => acc + curr, 0)
	const total = linesTotal + additionalTotal

	const formattedQuoteLines = quote_zones.map((zone: IQuoteZones) => {
		const filteredLines = quote_lines.filter(
			(line: IQuoteLine) => line.zone_id === zone.zone_id
		)

		const { total, weekly_hire_fee } = filteredLines.reduce(
			(acc, line) => {
				acc.total += Number(line.total) || 0
				acc.weekly_hire_fee += Number(line.weekly_hire_fee) || 0
				return acc
			},
			{ total: 0, weekly_hire_fee: 0 }
		)
		return {
			description: zone.zone_label + ' ' + zone.description,
			filteredLines,
			total,
			weekly_hire_fee,
		}
	})

	const files = quote_data?.files
		? quote_data?.files.filter((file: any) => {
				return file.type == 'External'
		  })
		: []

	const isImage = (fileUrl: string) => {
		if (!fileUrl) return false
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
		const extension = fileUrl.split('.').pop()?.toLowerCase()
		return extension ? imageExtensions.includes(extension) : false
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Quote Lines</Text>
					<Table type={ColumnTypes.quote_lines} data={formattedQuoteLines} />
				</View>
				<View>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>
				<View style={{ marginTop: 20 }}>
					<Table type={ColumnTypes.totals} data={total} />
				</View>

				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
					{/* <Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://www.toowoombascaffold.co.nz/wp-content/uploads/2021/05/NB Scaffold-Scaffold-Terms-of-Trade-2021.pdf">
							View our Terms and Conditions here
						</Link>
					</Text> */}
				</View>
			</Page>
			{files.length > 0 ? (
				<Page size="A4" style={styles.page}>
					<View style={styles.imageRow}>
						{files?.map(
							(
								image: {
									url: string
									description: string
									section_label: string
								},
								index: number
							) => (
								<View style={styles.image}>
									{isImage(image.url) ? (
										<Image
											key={index}
											src={image.url}
											style={{ maxWidth: '100%', maxHeight: '100%' }}
										/>
									) : (
										<Link src={image.url}>
											<Text
												style={{
													color: 'blue',
													textDecoration: 'underline',
												}}>
												Click here to view file
											</Text>
										</Link>
									)}
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 5,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Description:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.description}</Text>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 10,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Section:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.section_label}</Text>
								</View>
							)
						)}
					</View>
				</Page>
			) : null}
		</Document>
	)
}
