import {
	DateSelect,
	Dropdown,
	Input,
	MultiSelect,
	SideModal,
	Spinner,
	TextArea,
	TimeSelect,
} from 'common'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import {
	JobsServices,
	StaffServices,
	TimesheetServices,
	VisitTimesheetsServices,
} from 'services'
import {
	OptionsForDropdown,
	OptionsForDropdownArray,
	OptionsForDropdownFilter,
} from 'utilities'
import * as Yup from 'yup'

interface IProps {
	timesheet_id?: number
	heading: string
	setOpen: (open: boolean) => void
	open: boolean
}
interface IInitialValues {
	job_id: number
	visit_id: number
	staff_id: number
	time_in: string
	time_off: string
	date: string
	status: string
}

export const VisitTimesheetForm = ({
	timesheet_id,
	heading,
	setOpen,
	open,
}: IProps) => {
	const { data: visitTimesheetData, isLoading: visitTimesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheetsById(timesheet_id)

	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	const { data: jobData, isLoading: jobsLoading } = JobsServices.useJobs()

	const jobDataSorted = jobData?.sort((a: any, b: any) => b.id - a.id)

	const { updateVisitTimesheet } =
		VisitTimesheetsServices.useUpdateTimesheetById()
	const { createVisitTimesheet } =
		VisitTimesheetsServices.useCreateVisitTimesheet()
	console.log('tsData', visitTimesheetData)
	const initialValues: IInitialValues = {
		job_id: visitTimesheetData?.job_id || null,
		visit_id: visitTimesheetData?.visit_id || null,
		staff_id: visitTimesheetData?.staff_id || null,
		time_in: visitTimesheetData?.time_in || null,
		time_off: visitTimesheetData?.time_off || null,
		date: visitTimesheetData?.date || moment().format('DD/MM/YYYY'),
		status: visitTimesheetData?.status || 'Active',
	}

	const validationSchema = Yup.object({
		// lunch_break: Yup.number()
		// 	.required('Lunch Break is required')
		// 	.typeError('Lunch Break must be a number'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (timesheet_id) {
				console.log('Updating')
				const payload = [
					{
						id: timesheet_id,
						job_id: values.job_id,
						visit_id: values.visit_id,
						staff_id: values.staff_id,
						time_in: values.time_in,
						time_off: values.time_off,
						date: values.date,
						status: values.status,
					},
				]
				updateVisitTimesheet(payload)
			} else {
				console.log('Creating')
				const payload = [
					{
						job_id: values.job_id,
						visit_id: null,
						staff_id: values.staff_id,
						time_in: values.time_in,
						time_off: values.time_off,
						date: values.date,
						status: values.status,
					},
				]
				createVisitTimesheet(payload)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		formik.resetForm()
	}, [open])

	if (timesheet_id && (visitTimesheetLoading || staffLoading || jobsLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={timesheet_id ? 'update' : 'create'}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							title="Date"
							id="date"
							value={formik.values?.date}
							onChange={formik.setFieldValue}
							error={formik.errors.date}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="job_id"
							label="Job"
							options={
								timesheet_id
									? OptionsForDropdownArray(jobDataSorted, 'id', [
											'job_num',
											'client_name',
											'descriptionOfQuote',
									  ])
									: OptionsForDropdownFilter(
											jobDataSorted,
											'id',
											['job_num', 'client_name', 'descriptionOfQuote'],
											'job_status',
											'In Progress'
									  )
							}
							value={`${formik.values.job_id}`}
							onChange={formik.setFieldValue}
							error={formik.errors.job_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<TimeSelect
							id="time_in"
							title="Time On"
							onChange={formik.setFieldValue}
							value={formik.values.time_in}
							error={formik.errors.time_in}
						/>
					</div>
					<div className="w-1/2">
						<TimeSelect
							id="time_off"
							title="Time Off"
							onChange={formik.setFieldValue}
							value={formik.values.time_off}
							error={formik.errors.time_off}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="staff_id"
							label="Staff Member"
							options={OptionsForDropdown(staffData, 'id', 'staff_name')}
							value={formik.values.staff_id}
							onChange={formik.setFieldValue}
							error={formik.errors.staff_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="status"
							label="Status"
							options={[
								{ value: 'Active', label: 'Active' },
								{ value: 'Inactive', label: 'Inactive' },
								{ value: 'Completed', label: 'Completed' },
							]}
							value={formik.values.status}
							onChange={formik.setFieldValue}
							error={formik.errors.status}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
