import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateTimesheetById = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateVisitTimesheet = async (data: any) => {
		try {
			for (const timesheet of data) {
				await postRequest(
					AppRoutes.serverVisitTimesheetRoutes.updateVisitTimesheet,
					timesheet,
					timesheet.id
				)
			}
			showSuccess('Timesheet updated successfully')
			queryClient.refetchQueries(['visit_timesheets'])
		} catch (error) {
			showError('Something went wrong when updating the timesheet')
			throw new Error('Something went wrong updating Timesheet')
		}
	}

	return { updateVisitTimesheet }
}
