import { PencilIcon } from '@heroicons/react/24/solid'
import { Spinner, Table, Badge, TwoColumnDetails, Section } from 'common'
import { VisitForm } from 'components/Visits'
import { AppRoutes } from 'config'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { JobsServices, VisitServices } from 'services'
import { HandoverFrom } from './HandoverForm'

interface JobDetailsProps {
	job_id?: number
}

export const JobDetailView = ({ job_id }: JobDetailsProps) => {
	const location = useLocation()
	const [tabIndex, setTabIndex] = useState(1)
	const {
		data: job,
		isLoading,
		enableCreateUpdate,
	} = JobsServices.useJobById(job_id)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [handover, setHandover] = useState<any>({})
	const { data, isLoading: handoverLoading } =
		JobsServices.useHandoverByJobId(job_id)
	useEffect(() => {
		if (!data) return
		setHandover(data)
	}, [data])

	if (isLoading || handoverLoading) {
		return <Spinner />
	}

	return (
<TwoColumnDetails
					heading="Job Details"
					editBtn="Edit Job"
					editLink={{
						to: AppRoutes.privateRoutes.JobEdit.replace(':id', String(job_id) || ''),
						state: {
							background: location,
							name: 'editJob',
						},
					}}
					isEditable={enableCreateUpdate}>
					<Section title="Job #" content={job.job_num} />
					<Section title="Job Type" content={job.job_type} />
					<Section title="Client" content={job.clientData?.client_name} />
					<Section title="Site" content={job.site} />
					<Section
						title="Start Date"
						content={
							job.start_date
								? new Date(job.start_date).toLocaleDateString('en-NZ')
								: 'N/A'
						}
					/>
					<Section
						title="Finish Date"
						content={
							job.end_date && job.job_type !== 'Day Works'
								? new Date(job.end_date).toLocaleDateString('en-NZ')
								: 'N/A'
						}
					/>
					<Section title="Job Status" content={job.job_status} />
					<Section title="Status" content={job.status} />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
					Description
					</h2>
					<div />
					<Section
						title=""
						content={
							<div className="flex items-center px-3 border rounded-lg">
								{job.descriptionOfQuote}
							</div>
						}
					/>
					<Section title='' content='' />

					{/**Handover Details Section */}

					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						H&S Officer
					</h2>
					<div />
					<Section title="H&S Officer" content={handover.hs_officer} />
					<Section
						title="H&S Officer Phone #"
						content={handover?.hs_officer_phone}
					/>
					<Section
						title="H&S Officer Email"
						content={handover?.hs_officer_email}
					/>

					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Client Site Contact
					</h2>
					<div />
					<Section
						title="Client Site Contact"
						content={handover?.site_forman}
					/>
					<Section
						title="Client Site Contact Phone"
						content={handover?.site_forman_phone}
					/>
					<Section
						title="Client Site Contact Email"
						content={handover?.site_forman_email}
					/>
					<Section title="Invoice Type" content={handover?.invoiceType} />

					<Section
						title=""
						content={
							<HandoverFrom
								job_id={job_id}
								setHandover={setHandover}
								handover={handover}
								job={job}
							/>
						}
					/>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Notes
					</h2>
					<div />
					<Section
						title=""
						content={
							<div className="flex items-center px-3 border rounded-lg">
								{job.notes}
							</div>
						}
					/>
					<div />
				</TwoColumnDetails>
                	)
                }
                