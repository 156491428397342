import { useState } from 'react'
import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import { job_status_options, job_status_options_Edit } from 'models/jobs.model'
import moment from 'moment'
import { useEffect } from 'react'
import { ClientServices, ContactServices, JobsServices } from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'
import { VariationTaskForm } from './VariationForm'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { CreateClientsForm } from 'components/Clients'

const jobTypeOptions = [
	{
		value: 'Residential',
		label: 'Residential',
	},
	{
		value: 'Confined Spaces',
		label: 'Confined Spaces',
	},
	{
		value: 'Civil',
		label: 'Civil',
	},
	{
		value: 'Commercial',
		label: 'Commercial',
	},
	{
		value: 'Marine',
		label: 'Marine',
	},
	{
		value: 'Industrial',
		label: 'Industrial',
	},
]

const brandingOptions = [
	{
		value: 'NB Scaffold',
		label: 'NB Scaffold',
	},
]

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	client_id: number | null
	job_type: string
	branding: string
	site: string
	start_date: string
	end_date: string
	job_status: string
	notes: string
	status: string
	PO_Number: string
	Requester: string
	type: string
	descriptionOfQuote: string
	total_hours: number
	percentage_erect: number
	percentage_dismantle: number
	percentage_complete: number
	LastEditDate: string
	hire_rate: number | null
	task_value: number | null
}

export const JobForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const [jobType, setJobType] = useState('')
	const [clientForm, setClientForm] = useState(false)
	const [validationSchema, setValidationSchema] = useState({})
	const { data: clientsData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const { data: contactsData } = ContactServices.useContacts()
	const { createVariationTask } = JobsServices.useCreateVariationTask()

	const { createJob } = JobsServices.useCreateJob()
	const { update } = JobsServices.useUpdateJob()
	const { data: jobData, isLoading: jobLoading } = JobsServices.useJobById(
		job_id || undefined
	)

	const initialValues: IinitialValues = {
		client_id: jobData?.clientData.id || null,
		job_type: jobData?.job_type || '',
		branding: 'NB Scaffold',
		site: jobData?.site || '',
		start_date: jobData?.start_date
			? moment(jobData.start_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		end_date: jobData?.end_date
			? moment(jobData.end_date).format('DD/MM/YYYY').toString()
			: '',
		job_status: jobData?.job_status || 'Pending Handover',
		notes: jobData?.notes || '',
		status: jobData?.status || 'Active',
		descriptionOfQuote: jobData?.descriptionOfQuote || '',
		percentage_erect: 0,
		percentage_dismantle: 0,
		percentage_complete: 0,
		total_hours: 0,
		LastEditDate: '',
		PO_Number: '',
		Requester: '',
		type: '',
		hire_rate: 0,
		task_value: 0,
	}
	console.log(jobData)
	console.log(initialValues)

	const formik = useFormik<IinitialValues>({
		initialValues,
		validationSchema: Yup.object(validationSchema),
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const JobData = {
					client_id: values.client_id,
					job_type: values.job_type,
					branding: values.branding,
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
				}
				const jobResponse = await createJob(JobData)
				const {
					PO_Number,
					Requester,
					descriptionOfQuote,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					task_value,
					hire_rate,
					type,
				} = values

				const data = {
					PO_Number,
					Requester,
					descriptionOfQuote,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					LastEditDate: moment(values.LastEditDate, 'DD/MM/YYYY').toDate(),
					task_value,
					hire_rate,
					type,
				}
			}
			if (formType === 'update' && job_id) {
				const JobData = {
					client_id: values.client_id,
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					job_type: values.job_type,
					notes: values.notes,
					status: values.status,
					descriptionOfQuote: values.descriptionOfQuote,
				}
				await update(job_id, JobData)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		formik.setFieldValue('job_status', 'Pending Handover')
		setValidationSchema({
			client_id: Yup.string().required('The client is required'),
			job_type: Yup.string().required('The job type is required'),
		})
	}, [formik.values.job_type])

	if ((job_id && clientsLoading) || jobLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				{(
					<>
						<div className="flex items-center px-3">
							<div className="flex flex-col w-full">
								<Dropdown
									label="Client"
									id="client_id"
									options={OptionsForDropdown(clientsData, 'id', 'client_name')}
									value={formik.values.client_id}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.client_id}
								/>
								<div className="flex items-center pl-2 mb-2">
									<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
									<button
										type="button"
										className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
										onClick={() => setClientForm(true)}>
										Add New Client
									</button>
								</div>
							</div>
						</div>
						<div className="flex items-center px-3">
							<Dropdown
								label="Branding"
								id="branding"
								options={brandingOptions}
								value={formik.values.branding}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
							/>
						</div>
					</>
				)}
				<div className="flex items-center px-3">
					<div className="w-full px-2 py-2 h-full">
						<label className="block mb-1 text-sm font-medium text-gray-700">
							Site
						</label>
						<Autocomplete
							apiKey={googleApiKey}
							placeholder=""
							defaultValue={formik.values.site}
							style={{ width: '100%' }}
							className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-[40px] rounded-md shadow-sm pl-3 "
							onPlaceSelected={(place) => {
								formik.setFieldValue('site', place.formatted_address)
							}}
							options={{
								types: ['address'],
								componentRestrictions: { country: 'au' },
							}}
						/>
					</div>
				</div>

				<div className="flex items-center px-3">
					<DateSelect
						title="Start Date"
						id="start_date"
						value={formik.values.start_date}
						onChange={formik.setFieldValue}
					/>
					<DateSelect
						title="Finish Date"
						id="end_date"
						value={formik.values.end_date}
						onChange={formik.setFieldValue}
					/>
				</div>
				<div className="flex items-center px-3">
					<Dropdown
						label="Job Status"
						id="job_status"
						options={job_status_options}
						value={formik.values.job_status}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						disabled={formType === 'create'}
					/>
					<Dropdown
						label="Job Type"
						id="job_type"
						options={jobTypeOptions}
						value={formik.values.job_type}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.job_type}
					/>
				</div>
				{/* <div className="flex items-center px-3">
				<Input
					title="PO Number"
					id="po_number"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.driver_license}
					placeholder="Driver License Number"
				/>
				</div> */}
				<div className="flex items-center px-3">
					<TextArea
						id="descriptionOfQuote"
						title="Description"
						value={formik.values.descriptionOfQuote}
						error={formik.errors.descriptionOfQuote}
						rows={6}
						handleBlur={formik.handleBlur}
						handleChange={formik.handleChange}
					/>
				</div>
				<div className="flex items-center px-3">
					<TextArea
						id="notes"
						title="Notes"
						value={formik.values.notes}
						error={formik.errors.notes}
						rows={6}
						handleBlur={formik.handleBlur}
						handleChange={formik.handleChange}
					/>
				</div>

				{ job_id !== undefined ? (<Dropdown
						label="Status"
						id="status"
						options={active_inactive_options}
						value={formik.values.status}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.status}
					/>) : null}

				<CreateClientsForm
					open={clientForm}
					setOpen={setClientForm}
					heading="Create New Client"
					formType="create"
				/>
			</SideModal>
		</>
	)
}
