import { co } from '@fullcalendar/core/internal-common'
import { Badge, Spinner, Table } from 'common'
import moment from 'moment'
import {
	JobsServices,
	StaffServices,
	VisitServices,
	VisitTimesheetsServices,
} from 'services'

interface VisitsTimesheetsTableProps {
	job_id?: number
}
export const VisitsTimesheetsTable = ({
	job_id,
}: VisitsTimesheetsTableProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)

	if (timesheetLoading) {
		return <Spinner />
	}

	const rowData = timesheetData?.map((timesheet: any) => {
		console.log('timesheet', timesheet)
		const visit: any = timesheet.visit
		const job: any = timesheet?.job
		const staff: any = timesheet?.staff
		const start_time = moment(timesheet.time_in, 'HH:mm')
		const end_time = moment(timesheet.time_off, 'HH:mm')
		const difference: number = end_time.diff(start_time, 'hours', true) || 0
		return {
			record_id: timesheet?.id,
			staff_id: staff?.id,
			id:
				String(job?.id) +
				'_' +
				String(visit?.id) +
				'_' +
				String(staff?.id),
			visit_id: visit?.id,
			name: staff?.staff_name,
			start_time: timesheet?.time_in,
			end_time: timesheet?.time_off,
			date: timesheet?.date,
			job: job?.job_num,
			description: job?.descriptionOfQuote,
			client_name: job?.clientData?.client_name,
			site: job?.site,
			hours: difference,
			confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
			timesheet_status: timesheet?.status,
		}
	}
	)

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: number }) => {
				return row.visit_id
					? <Badge type={'Green'} text={String(row.visit_id)} />
					: <Badge type={'Orange'} text={'Manual Entry'} />
			},
		},
		{
			field: 'name',
			header: 'Name',
		},
		{ field: 'start_time', header: 'Time In' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'hours', header: 'Hours' },
		{
			field: 'confined_spaces',
			header: 'Confined Spaces?',
			body: (row: { confined_spaces: boolean }) => {
				return row.confined_spaces ? (
					<Badge type={'Warning'} text={'Confined Space'} />
				) : (
					<Badge type={'Green'} text={'No'} />
				)
			},
		},
		{ field: 'timesheet_status', header: 'Timesheet Status' },
	]

	return (
		<>
			<Table
				columns={columns}
				data={rowData}
				groupBy="visit_id"
				isLoading={false}
				title="Visits Timesheets"
				disableButtons
			/>
		</>
	)
}
