import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { JobVisitsSchedulerUtils } from 'common/Scheduler'
import { PageHeading, Spinner } from 'common'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'
import { PlusIcon } from '@heroicons/react/24/solid'
import { DateSelectArg } from '@fullcalendar/core'
interface IFormatResource {
	id: string
	title: string
}

export function Scheduler() {
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const [selectedDate, setSelectedDate] = useState({
		start: '',
		team_leader: '',
	})
	const {
		visitsData,
		visitsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = JobVisitsSchedulerUtils()
	const formatResources = () => {
		if (visitsData) {
			const staffCrewLeaders: IFormatResource[] = []
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			visitsData.map((visit: any) => {
				if (visit?.teamLeaderData?.type === 'Crew Leader') {
					staffCrewLeaders.push({
						id: visit?.teamLeaderData?.id,
						title: visit?.teamLeaderData?.staff_name,
					})
				}
			})
			return staffCrewLeaders
		}
		return []
	}

	useEffect(() => {
		if (!openVisitForm) {
			setSelectedDate({
				start: '',
				team_leader: '',
			})
		}
	}, [openVisitForm])

	const handleDateSelect = (selectInfo: DateSelectArg) => {
		setSelectedDate({
			start: selectInfo.startStr,
			team_leader: selectInfo.resource ? selectInfo.resource.id : '',
		})
		setOpenVisitForm(true)
	}

	if (visitsLoading) {
		return <Spinner />
	}

	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenVisitForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Schedule Visit
				</button>
			</div>
			<FullCalendar
				resourceAreaHeaderContent="Staff"
				resourceAreaWidth="8%"
				filterResourcesWithEvents
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				slotMinWidth={100}
				views={{
					Day: {
						type: 'resourceTimelineDay',
						slotDuration: { days: 1 },
						slotLabelFormat: [
							{ weekday: 'long' }, // lower level of text
						],
						eventContent,
					},
					Week: {
						type: 'resourceTimelineWeek',
						slotLabelInterval: { days: 1 },
						weekends: true,
						slotLabelFormat: [
							{ weekday: 'short' }, // lower level of text
						],
						eventContent,
					},
					Month: {
						type: 'resourceTimelineMonth',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				initialView="Week"
				height="auto"
				editable
				selectable
				select={handleDateSelect}
				dayMaxEvents
				firstDay={1}
				resources={formatResources()}
				events={formatEvents()}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
			<VisitForm
				heading="Schedule Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
				startDate={selectedDate.start}
				teamLeader={selectedDate.team_leader}
			/>
		</StyledWrapper>
	)
}
