import React from 'react'
import { Input, Dropdown, DateSelect, Checkbox, CreateFile } from 'common'
import { IStaffRow } from 'models/staff.model'
interface DriverLicenseProps {
	values: IStaffRow
	staff?: IStaffRow[]
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
	setFieldTouched: (
		field: string,
		isTouched?: boolean | undefined,
		shouldValidate?: boolean | undefined
	) => void
}

const licenseOptions = [
	{ value: 'Full', label: 'Full' },
	{ value: 'Restricted', label: 'Restricted' },
	{ value: 'Provisional', label: 'Provisional' },
	{ value: 'Learner License', label: 'Learner License' },
	{ value: 'International', label: 'International' },
]
// Class options:  C, LR, MR, HR
const classOptions = [
	{ value: 'C', label: 'C' },
	{ value: 'LR', label: 'LR' },
	{ value: 'MR', label: 'MR' },
	{ value: 'HR', label: 'HR' },
]

export const DriverLicense: React.FC<DriverLicenseProps> = ({
	values,
	staff,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = () => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				value: item.staff_name || '',
				label: item.staff_name,
			}))
		}
		return []
	}
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Driver License
			</h3>
			<div className="flex items-center px-2">
				<Input
					title="Driver License Number"
					id="driver_license"
					type="text"
					handleChange={handleChange}
					handleBlur={handleBlur}
					value={values.driver_license}
					placeholder="Driver License Number"
				/>
				<Dropdown
					label="Driver License Type"
					id="license_type"
					options={licenseOptions}
					value={values.license_type}
					onChange={setFieldValue}
					onBlur={setFieldTouched}
				/>
			</div>
			<Checkbox
				title="Driver License Class"
				options={classOptions}
				id="license_class2"
				values={JSON.parse(values?.license_class2 || '[]')}
				onChange={(id: string, values: string[]) => {
					setFieldValue(id, JSON.stringify(values))
				}}
			/>
			<div className="flex items-center px-2">
				<Input
					title="Endorsement"
					id="endorsement"
					type="text"
					handleChange={handleChange}
					handleBlur={handleBlur}
					value={values.endorsement}
					placeholder=""
				/>
				<DateSelect
					title="Expiry"
					id="endorsement_expiry"
					value={`${values.endorsement_expiry}`}
					onChange={setFieldValue}
				/>
			</div>
			<div className="flex items-center">
				<div className="w-1/2 px-2">
					<h4 className="text-md font-normal leading-5 px-4">Photo - Front</h4>
					<CreateFile
						field="photo_front"
						setFieldValue={setFieldValue}
						value={values.photo_front}
					/>
				</div>
				<div className="w-1/2">
					<h4 className="text-md font-normal leading-5 px-4">Photo - Back</h4>
					<CreateFile
						field="photo_back"
						setFieldValue={setFieldValue}
						value={values.photo_back}
					/>
				</div>
			</div>
			<div className="w-1/2 px-2">
				<Dropdown
					label="Driver License Assessed By"
					id="license_assessed_by"
					value={values.license_assessed_by}
					onChangeVal="license_assessed_by"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					options={renderStaffList()}
				/>
			</div>
		</div>
	)
}
