import { AddressServices } from 'services'
import { Spinner, Table } from 'common'
import { PencilIcon } from '@heroicons/react/24/solid'
import { AddressForm } from 'components/Addresses'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'

interface AddressTableProps {
	client_id?: number
}

export const AddressTable = ({ client_id }: AddressTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data: address, isLoading } =
		AddressServices.useAddressesByClientId(client_id)

	if (isLoading) {
		return <Spinner />
	}

	const columns = [
		{
			field: 'street_number',
			header: 'Street Number',
		},
		{
			field: 'route',
			header: 'Street',
		},
		{
			field: 'sublocality',
			header: 'Suburb',
		},
		{
			field: 'locality',
			header: 'City',
		},
        {
            field: 'country',
            header: 'Country',
        },
        {
            field: 'postal_code',
            header: 'Postal Code',
        },
		{
			field: 'id',
			header: 'Edit',
			body: (rowData: { id: string }) => (
				<Link
					to={{
						pathname: AppRoutes.privateRoutes.ClientAddressEdit.replace(
							':id',
							rowData.id
						),
					}}
					state={{ background: location, name: 'editAddress' }}>
					<PencilIcon className="text-gray-600 h-4 w-4" />
				</Link>
			),
		},
	]

	return (
		<>
			<Table
				title="Addresses"
				columns={columns}
				data={address}
				isLoading={isLoading}
				ActionName="Add New Address"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<AddressForm
				client_id={client_id}
				formType="create"
				heading="Create Address"
				open={openCreate}
				setOpen={setOpenCreate}
			/>
		</>
	)
}
