import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import {
	DataTableServices,
	VisitServices,
	StaffServices,
	VehicleServices,
	JobsServices,
	VisitTimesheetsServices,
} from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'

import { VisitForm } from 'components/Visits'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import moment from 'moment'

export const DailySummaryMainTable = () => {
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const { data, isLoading } = VisitServices.useCompletedVisits()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: vehicleData, isLoading: vehicleLoading } =
		VehicleServices.useVehicles()
	const { data: taskData, isLoading: taskLoading } = JobsServices.useTask()

	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	console.log(data)
	const calcHours = (startTime: string, finishTime: string) => {
		const start = moment(startTime, 'HH:mm')
		const finish = moment(finishTime, 'HH:mm')

		if (finishTime && finish) {
			const duration = moment.duration(finish.diff(start))
			const hours = duration.asHours()
			return hours
		}
		return null
	}

	const footerTemplate = (data: any) => (
		<>
			<td
				colSpan={7}
				style={{ textAlign: 'right' }}
				className="bg-gray-100 font-normal">
				Total Hours
			</td>
			<td colSpan={1} className="bg-gray-100 font-semibold">
				{calculateTimesheetTotal(data)}
			</td>
		</>
	)

	const calculateTimesheetTotal = (row: any) => {
		let total = 0
		if (timesheetData) {
			for (const timesheet of timesheetData) {
				if (Number(timesheet.job_id) === row?.job_id) {
					const hours = timesheet.time_off
						? Number(calcHours(timesheet.time_in, timesheet.time_off))
						: 0
					total += hours
				}
			}
		}
		return total
	}

	const quoteHoursCalculation = (row: any) => {
		let total = 0
		if (taskData) {
			for (const task of taskData) {
				if (Number(task.job_id) === row?.job_id) {
					const hours = Number(task.total_hours)
					total += hours
				}
			}
		}
		return total
	}

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [
			'jobData.job_num',
			'teamLeaderData.staff_name',
			'jobData.site',
			'notes',
			'visit_status',
			'type',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading
				title="Daily Summary"
				createBtn="Schedule Visit"
				isEditable={false}
				setOpen={setOpenVisitForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					loading={
						isLoading ||
						staffLoading ||
						vehicleLoading ||
						taskLoading ||
						timesheetLoading
					}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					sortField="job_id"
					sortOrder={-1}
					rowGroupHeaderTemplate={subHeader}
					rowGroupFooterTemplate={footerTemplate}
					rowGroupMode="subheader"
					groupRowsBy="job_id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					stripedRows
					header={header}
					emptyMessage="No Visit Scheduler found.">
					<Column
						field="date"
						header="Date"
						body={(rowData: { date: string; job_id: string }) => {
							return (
								<Link
									to={AppRoutes.privateRoutes.JobDetails.replace(
										':id',
										rowData.job_id
									)}>
									{rowData.date}
								</Link>
							)
						}}
					/>
					<Column field="jobData.job_num" header="Job Number" />
					<Column field="jobData.site" header="Site" />
					<Column field="teamLeaderData.staff_name" header="Team Leader" />
					<Column
						field="staff_ids"
						header="Staff"
						body={(rowData: { id:string, staff_ids: string[] }) => {
							let result: string[] = [];
							rowData.staff_ids.forEach(function(item) {
								if(result.indexOf(item) < 0) {
									result.push(item);
								}
							});
							return result.map((staff_id) => {
								if (!staffData) return null
								const staff = staffData.find(
									(item) => Number(item.id) === Number(staff_id)
								)
								return (
									<span key={`visit_${rowData.id}_staff_${staff?.id}`}>
										{staff?.staff_name}
										<br />
									</span>
								)
							})
						}}
					/>
					<Column field="time_on" header="Start Time" />
					<Column field="time_off" header="End Time" />
					<Column
						field='total_hours_calculated'
						header="Total Hours"
						body={(row) => {
							return calcHours(row.time_on, row.time_off)
						}}
						showFilterMatchModes={false}
						style={{ minWidth: '4rem', height: '45px' }}
					/>
					<Column
						header="Quoted Hours"
						field='quoted_hours_calculated'
						body={(row) => {
							return quoteHoursCalculation(row)
						}}
						showFilterMatchModes={false}
						style={{ minWidth: '4rem', height: '45px' }}
					/>
					<Column
						field="vehicle_ids"
						header="Vehicles"
						body={(rowData: { id:string, vehicle_ids: string[] }) => {
							return rowData.vehicle_ids.map((vehicle_id) => {
								if (!vehicleData) return null
								const vehicle = vehicleData.find(
									(item: { id: string }) =>
										Number(item.id) === Number(vehicle_id)
								)
								return (
									<span key={`visit_${rowData.id}_vehicle_${vehicle?.id}`}>
										{vehicle?.Rego}
										<br />
									</span>
								)
							})
						}}
					/>
					<Column
						field="task_ids"
						header="Task"
						body={(rowData:  { id: string,task_ids: string[] }) => {
							return rowData.task_ids.map((task_id) => {
								if (!taskData) return null
								const task = taskData.find(
									(item: { id: string }) => Number(item.id) === Number(task_id)
								)

								return (
									<span key={`visit_${rowData.id}_task_${task?.id}`}>
										{task?.zone_label} - {task?.type}
										<br />
									</span>
								)
							})
						}}
					/>
					<Column field="notes" header="Notes" />
					<Column field="type" header="Type" />
					<Column field="visit_status" header="Visit Status" />
					<Column field="status" header="Status" />
				</DataTable>
			</Container>
			<VisitForm
				heading="Schedule Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
			/>
		</div>
	)
}

const subHeader = (data: { jobData: { job_num: number } }) => {
	return (
		<div className="text-gray-900 font-bold">
			<div>{data.jobData.job_num}</div>
		</div>
	)
}
