import { Image, Text, View } from '@react-pdf/renderer'
import { AppConfig } from 'config'
import * as logo from 'assets/logo.png'
import { stylesHeading } from './styles'

export const Heading = () => {
	return (
		<View style={stylesHeading.headingContainer}>
			<Image style={stylesHeading.logo} src={logo.default} />
			<View style={stylesHeading.headingContact}>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>
						<Text style={{ fontSize: '12' }}>N&B Scaffold Pty ltd</Text>
						{'\n\n'}ABN: 91 166 744 350{'\n'}E: info@nbscaffold.com.au{'\n'}Ph:
						07 3805 6889{'\n'}38 Ellerslie Road,{'\n'}Meadowbrook, QLD 4131
					</Text>
					<Text style={stylesHeading.subText}></Text>
				</View>
			</View>
		</View>
	)
}
