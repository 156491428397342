import { useState, useEffect } from 'react'
import { Button, SimpleCreateFile } from 'common'

interface FormProps {
	setFieldValue: (field: string, value: string | number) => void
	values: any
}

const SiteFiles = ({ setFieldValue, values }: FormProps) => {
	const [numFiles, setNumFiles] = useState(4)
	const [data, setData] = useState<any>([])

	useEffect(() => {
		if (values?.files) {
			setData(values.files)
			setNumFiles(values.max_files)
		}
	}, [values])

	const handleNumFilesChange = (newNumFiles: number) => {
		setFieldValue('max_files', newNumFiles)
		setNumFiles(newNumFiles)
	}

	const renderCreateFiles = () => {
		const createFiles = []
		for (let i = 1; i <= numFiles; i++) {
			const fieldName = `file_${i}`
			createFiles.push(
				<div key={fieldName} className="border rounded-md">
					<h4 className="text-md font-normal leading-5 px-4 py-4 ">{`File ${i}`}</h4>
					<SimpleCreateFile
						setFieldValue={setFieldValue}
						setData={setData}
						data={data}
						value={
							values?.files?.filter(
								(file: { index: number }) => file?.index === i - 1
							)[0]
						}
						index={i - 1}
						zones={values.zones}
						setNumFiles={setNumFiles}
						numFiles={numFiles}
					/>
				</div>
			)
		}
		return createFiles
	}

	return (
		<div className="w-4/5 mb-8 px-2">
			<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
				Site Files
			</h2>
			<div
				className={`grid grid-cols-2 grid-rows-${Math.ceil(
					numFiles / 2
				)} gap-2 w-1/2`}>
				{renderCreateFiles()}
			</div>
			<div className="mt-4">
				<Button
					size="sm"
					variant="primary"
					onClick={() =>
						numFiles < 10 ? handleNumFilesChange(Number(numFiles) + 1) : null
					}>
					Add File
				</Button>
			</div>
		</div>
	)
}

export default SiteFiles
