import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
	FolderOpenIcon,
	DocumentDuplicateIcon,
	DocumentArrowDownIcon,
	PencilSquareIcon,
	PencilIcon,
} from '@heroicons/react/24/outline'
import { PageHeading, Container, DataTableHeader } from 'common'
import { DataTableServices, QuoteServices } from 'services'

import { EmailStatus, ApproveQuote, DeclineQuote } from 'components/Quotes'

import { AppRoutes } from 'config'
import { Link } from 'react-router-dom'
import { numberFormat } from 'utilities'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { IQuoteForm } from 'models/simple_quotes.model'
import moment from 'moment'

export const QuotesMainTable = () => {
	const { data, isLoading } = QuoteServices.useQuotes()
	const { fetchQuoteById } = QuoteServices.useQuoteById()
	const { fetchQuoteLinesById } = QuoteServices.useQuoteLinesById()
	const { fetchQuoteRatesById } = QuoteServices.useQuoteRatesById()
	const { fetchQuoteZonesById } = QuoteServices.useQuoteZonesById()
	const { fetchQuoteAddOnsById } = QuoteServices.useQuoteAddonsById()
	const { createQuote } = QuoteServices.useCreateQuote()
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterInitialValue: 'Pending',
				filterName: 'status',
				filterOptions: ['Pending', 'Approved', 'Declined'],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
			{
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterInitialValue: null,
				filterName: 'job_type',
				filterOptions: [
					'Residential',
					'Confined Spaces',
					'Commercial',
					'Marine',
					'Industrial',
				],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
		],
		additionalGlobalFilterFields: [
			'quote_num',
			'clientData.client_name',
			'contactData.name',
			'status',
			'formattedUpdatedAt',
		],
	})
	const duplicateQuote = async (quote_id: number) => {
		try {
			const quote = await fetchQuoteById(quote_id)
			const quote_lines = await fetchQuoteLinesById(quote_id)
			const quote_rates = await fetchQuoteRatesById(quote_id)
			const quote_zones = await fetchQuoteZonesById(quote_id)
			const quote_addons = await fetchQuoteAddOnsById(quote_id)
			console.log(quote, 'quote')
			console.log(quote_lines, 'quote_lines')
			console.log(quote_rates, 'quote_rates')
			console.log(quote_zones, 'quote_zones')
			console.log(quote_addons, 'quote_addons')
			const quote_lines_new = quote_lines.map((line: any) => {
				const newLine = { ...line }
				delete newLine.created_at
				delete newLine.updated_at
				delete newLine.quote_id
				delete newLine.id
				newLine.erect_and_dismantle = Number(newLine.erect_and_dismantle)
				newLine.total = Number(newLine.total)
				return newLine
			})
			const quote_rates_new = quote_rates.map((rate: any) => {
				const newRate = { ...rate }
				delete newRate.quote_id
				delete newRate.id
				return newRate
			})
			const quote_addons_new = quote_addons.map((addon: any) => {
				const newAddon = { ...addon }
				delete newAddon.hire_fee
				delete newAddon.created_at
				delete newAddon.updated_at
				delete newAddon.quote_id
				delete newAddon.id
				newAddon.fixed_charge = Number(newAddon.fixed_charge)
				return newAddon
			})
			console.log(quote_addons_new, 'new')
			const quote_zones_new = quote_zones.map((zone: any) => {
				const newZone = { ...zone }
				delete newZone.quote_id
				delete newZone.id
				return newZone
			})

			const newQuote: IQuoteForm = {
				quote_type: 'New',
				job_type: quote.job_type,
				variation_job_id: quote.variation_job_id,
				PO_Number: quote.PO_Number,
				max_zones: quote.max_zones,
				client: quote.client,
				client_contact: quote.client_contact,
				scope_of_work: quote.scope_of_work,
				estimator: quote.estimator,
				street: quote.street,
				fullAddress: quote.street,
				country: quote.country,
				street2: quote.street2,
				city: quote.city,
				postal: quote.postal,
				quote_lines: quote_lines_new,
				quote_additional_lines: quote_addons_new,
				terms: quote.terms,
				erectDismantleTotal: quote.erectDismantleTotal,
				additionalTotal: quote.additionalTotal,
				weekTotal: quote.weekTotal,
				total: quote.total,
				rates: quote_rates_new,
				zones: quote_zones_new,
				file_1: quote.file_1,
				file_2: quote.file_2,
				file_3: quote.file_3,
				file_4: quote.file_4,
				files: [],
				max_files: 0,
			}

			console.log(newQuote, 'newQuote')

			const response = await createQuote(newQuote)
			console.log(response)
		} catch (error) {
			console.error('Error fetching quote:', error)
		}
	}

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'status',
				value: 'Approved',
				color: 'success',
			},
			{
				field: 'status',
				value: 'Declined',
				color: 'danger',
			},
			{
				field: 'status',
				value: 'Pending',
				color: 'warning',
			},
			// {
			// 	field: 'emailStatus',
			// 	value: 'Customer Accepted',
			// 	color: 'success',
			// },
			// {
			// 	field: 'emailStatus',
			// 	value: 'Customer Queried',
			// 	color: 'warning',
			// },
			// {
			// 	field: 'emailStatus',
			// 	value: 'Customer Declined',
			// 	color: 'danger',
			// },
		],
	})

	const preprocessedData = data?.map((data: any) => {
		return {
			...data,
			formattedUpdatedAt: moment(data?.updatedAt).format('DD/MM/YYYY'),
		}
	})

	return (
		<>
			<PageHeading
				title="Quotes"
				createBtn={'Create Quote'}
				navigate={AppRoutes.privateRoutes.QuotesCreate}
			/>
			<Container>
				<div style={{ width: '100%', overflowX: 'auto' }}>
					<DataTable
						ref={dataTableReference}
						value={preprocessedData}
						paginator
						showGridlines
						rows={100}
						rowsPerPageOptions={[10, 25, 50, 100]}
						loading={isLoading}
						dataKey="id"
						sortField="id"
						sortOrder={-1}
						filters={filters}
						globalFilterFields={globalFilterFields}
						header={header}
						stripedRows
						responsiveLayout="scroll"
						emptyMessage="No Quotes found.">
						<Column
							field="createdAt"
							header="Created At"
							body={(rowData) => {
								return (
									<span>
										{new Date(rowData.createdAt).toLocaleDateString('en-NZ')}
									</span>
								)
							}}
						/>
						<Column
							field="quote_num"
							header="Quote # (Details)"
							body={(rowData) => {
								return (
									<Link
										to={AppRoutes.privateRoutes.QuotesDetail.replace(
											':id',
											rowData.id
										)}
										className="flex items-center">
										<FolderOpenIcon className="h-4 w-4 text-gray-500" />
										{rowData.quote_num}
									</Link>
								)
							}}
						/>
						<Column field="clientData.client_name" header="Client Name" />
						<Column field="contactData.name" header="Contact Name" />
						<Column field="contactData.email" header="Contact Email" />
						<Column
							field="street"
							header="Site Address"
							style={{ maxWidth: '150px', textAlign: 'left' }}
							headerStyle={{ maxWidth: '150px' }}
						/>
						<Column
							field="scope_of_work"
							header="Description"
							style={{ maxWidth: '200px', textAlign: 'left' }}
						/>
						<Column
							field="job_type"
							header="Job Type"
							style={{ maxWidth: '150px', textAlign: 'left' }}
							{...FilterColumn.job_type}
						/>
						<Column
							field="estimatorData.staff_name"
							header="Estimator"
							style={{ maxWidth: '70px', textAlign: 'left' }}
						/>
						<Column
							field="total"
							header="Total Amount"
							body={(rowData) => {
								return <span>{numberFormat.format(rowData.total)}</span>
							}}
						/>
						<Column
							field="emailStatus"
							header="Email Status"
							body={(rowData) => {
								return (
									<EmailStatus
										quote={rowData}
										emailStatus={rowData.emailStatus}
										quoteId={rowData.id}
										onlineAccept={false}
									/>
								)
							}}
						/>
						{/* <Column
							field="viewlink"
							header="Open Customer Link (For Testing)"
							exportable={false}
							bodyStyle={{ alignContent: 'center' }}
							body={(rowData) => {
								return (
									<Link
										to={AppRoutes.publicRoutes.AcceptQuote.replace(
											':token',
											rowData.token
										)}
										className="flex justify-center items-center">
										<PencilIcon className="h-4 w-4 text-gray-500" />
									</Link>
								)
							}}
						/> */}
						<Column
							sortable
							field="updatedAt"
							header="Last updated"
							body={(rowData) => {
								return (
									<span className="ml-3">
										{new Date(rowData.updatedAt).toLocaleDateString('en-NZ')}
									</span>
								)
							}}
							{...FilterColumn.updatedAt}
						/>
						<Column
							field="id"
							header="Operations"
							exportable={false}
							body={(rowData) => {
								return (
									<div className="flex-row space-y-2 mt-2 mb-2 items-start">
										<div>
											<ApproveQuote
												quoteId={rowData.id}
												status={rowData.status}
											/>
										</div>
										<div>
											<DeclineQuote
												quoteId={rowData.id}
												status={rowData.status}
											/>
										</div>
										<Link
											to={AppRoutes.privateRoutes.QuotesEdit.replace(
												':id',
												rowData.id
											)}
											className="flex items-center">
											<PencilSquareIcon className="h-4 w-4 text-gray-500" />
											<label className="px-2 items-center text-sm font-medium hover:cursor-pointer">
												Edit
											</label>
										</Link>
										<Link
											to={AppRoutes.privateRoutes.QuotesPDF.replace(
												':id',
												rowData.id
											)}
											className="flex items-center">
											<DocumentArrowDownIcon className="h-4 w-4 text-gray-500" />
											<label className="px-2 items-center text-sm font-medium hover:cursor-pointer">
												View PDF
											</label>
										</Link>
										<button
											onClick={() => duplicateQuote(rowData.id)}
											className="flex items-center">
											<DocumentDuplicateIcon className="h-4 w-4 text-gray-500" />
											<span className="px-2 items-center text-sm font-medium">
												Duplicate
											</span>
										</button>
									</div>
								)
							}}
						/>
						{/* <Column
							field="id"
							header="Decline"
							exportable={false}
							body={(rowData) => {
								return (
									<DeclineQuote quoteId={rowData.id} status={rowData.status} />
								)
							}}
						/>
						<Column
							field="id"
							header="Edit"
							exportable={false}
							bodyStyle={{ alignItems: 'center' }}
							body={(rowData) => {
								if (rowData.status !== 'Pending') {
									return <PencilSquareIcon className="h-4 w-4 text-gray-200" />
								}
								return (
									<Link
										to={AppRoutes.privateRoutes.QuotesEdit.replace(
											':id',
											rowData.id
										)}
										className="flex justify-center items-center">
										<PencilSquareIcon className="h-4 w-4 text-gray-500" />
									</Link>
								)
							}}
						/>
						<Column
							field="id"
							header="Export"
							exportable={false}
							bodyStyle={{ alignContent: 'center' }}
							body={(rowData) => {
								return (
									<Link
										to={AppRoutes.privateRoutes.QuotesPDF.replace(
											':id',
											rowData.id
										)}
										className="flex justify-center items-center">
										<DocumentArrowDownIcon className="h-4 w-4 text-gray-500" />
									</Link>
								)
							}}
						/>
						<Column
							field="id"
							header="Duplicate"
							exportable={false}
							bodyStyle={{ alignContent: 'center' }}
							body={() => {
								// (rowData)
								return (
									<Link
										to={AppRoutes.privateRoutes.Quotes}
										className="flex justify-center items-center">
										<DocumentDuplicateIcon className="h-4 w-4 text-gray-500" />
									</Link>
								)
							}}
						/> */}
					</DataTable>
				</div>
			</Container>
		</>
	)
}
