export const visitTypeOptions = [
	{
		value: 'Install',
		label: 'Install',
	},
	{
		value: 'Dismantle',
		label: 'Dismantle',
	},
	{
		value: 'Alteration',
		label: 'Alteration',
	},
	{
		value: 'Remedial',
		label: 'Remedial',
	},
	{
		value: 'Travel',
		label: 'Travel',
	},
	{
		value: 'Administration',
		label: 'Administration',
	},
	{
		value: 'Downtime',
		label: 'Downtime',
	},
	{
		value: 'Inspection',
		label: 'Inspection',
	},
	{
		value: 'Other',
		label: 'Other',
	},
]

export const visitsStatusOptions = [
	{ label: 'Pending Prestart', value: 'Pending Prestart' },
	{ label: 'Pending Close of Visit', value: 'Pending Close of Visit' },
	{ label: 'Completed', value: 'Completed' },
]
