import { IQuoteLine, IRates } from 'models/simple_quotes.model'

export const calculateLine = (
	rates: IRates[],
	line: IQuoteLine,
	field?: string
) => {
	const perimeterRate =
		rates.find((rate) => rate.service === line.type)?.perimeter || 0
	const laborRate = rates.find((rate) => rate.service === line.type)?.labor || 0
	const weightRate =
		rates.find((rate) => rate.service === line.type)?.weight || 0
	const materialPerWeekRate =
		rates.find((rate) => rate.service === line.type)?.material_per_week || 0

	line.perimeter = Number((line.bays * perimeterRate!).toFixed(2))
	line.square_meters = Number((line.perimeter * line.height).toFixed(2))
	line.labor_cost = Number((line.square_meters * laborRate!).toFixed(2))
	line.weight = Number((line.square_meters * weightRate!).toFixed(2))
	line.weekly_hire_fee = Number(
		((line.weight / 1000) * materialPerWeekRate!).toFixed(2)
	)
	line.material_hire = Number((line.weekly_hire_fee * line.weeks).toFixed(2))
	line.total =
		line.labor_cost +
		line.material_hire +
		Number(line.adjustment) +
		Number(line.transport)

	if (line.total) {
		line.ground_up = Number((line.square_meters * 9.5).toFixed(2))
		line.dismantle = Number((line.square_meters * 5.5).toFixed(2))
	}

	return line
}
