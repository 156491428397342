import { IQuoteLine } from 'models/simple_quotes.model'

interface TotalsProps {
	quoteLines: IQuoteLine[]
}

export const QuoteLineTotals = ({ quoteLines }: TotalsProps) => {
	const totalBays = quoteLines.reduce(
		(acc, line) => acc + Number(line.bays || 0),
		0
	)
	const totalArea = quoteLines.reduce(
		(acc, line) => acc + Number(line.square_meters || 0),
		0
	)
	const totalWeight = quoteLines.reduce(
		(acc, line) => acc + Number(line.weight || 0),
		0
	)

	return (
		<div className="w-5/12 px-2 mb-16">
			<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
				Scaffolding, Propping & Optional Extras Totals
			</h2>
			<table className="min-w-full border-collapse border border-gray-200">
				<thead>
					<tr>
						<th className="w-3/4 py-1 bg-gray-100 border-b border-gray-200"></th>
						<th className="w-1/4 py-1 bg-gray-100 border-b border-gray-200"></th>
					</tr>
				</thead>
				<tbody>
					<tr className="border-b border-gray-200">
						<th
							className="text-left text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs px-4 py-2 bg-gray-100"
							scope="row">
							Total Bays
						</th>
						<td className="text-right text-sm font-medium text-gray-900 px-4 py-2">
							{Number.isInteger(totalBays) ? totalBays : totalBays.toFixed(1)}
						</td>
					</tr>
					<tr className="border-b border-gray-200">
						<th
							className="text-left text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs px-4 py-2 bg-gray-100"
							scope="row">
							Total Area (m2)
						</th>
						<td className="text-right text-sm font-medium text-gray-900 px-4 py-2">
							{Number.isInteger(totalArea) ? totalArea : totalArea.toFixed(1)}
						</td>
					</tr>
					<tr>
						<th
							className="text-left text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs px-4 py-2 bg-gray-100"
							scope="row">
							Total Weight (kg)
						</th>
						<td className="text-right text-sm font-medium text-gray-900 px-4 py-2">
							{Number.isInteger(totalWeight)
								? totalWeight
								: totalWeight.toFixed(1)}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
